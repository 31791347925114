// Here you can add other styles
.bg-light {
    background-color: #2D9AEB!important;
}
.navbar a{
    color: #FFF;
}
.profile .nav-tabs a{
    width:33.3%
} 
.login{
    background-color: #f5f5f5;
}
.login .logo {
    background-color: #2D9AEB;
    padding:15px;
    border-radius: 5px
}
.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}
.form-signin .checkbox {
    font-weight: 400;
}
.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}
.form-signin .form-control:focus {
    z-index: 2;
}
.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.main-content{margin:10px 5px;}
table a{ color:#2D9AEB;}
.setbgcolor{ background-color: #2D9AEB;}

.err_below{
    color:red;
    font-size: 11px;
}
.nav-link {
    display: block;
    padding: .5rem 0.3rem !important;
}
.navbar-expand{
    font-size: 14px !important;
}
.invoice{
    float: right;
    margin: -205px 166px 30px 0px;
    font-size: 14px;
}
.addrleft{
    display: block;
    /* block-size: 114px; */
    width: 35%;
    margin: 0px 0px 32px 152px;
    font-size: 14px;
}
.crstx{
    margin: 8px 0px 0px 0px;
}
.textsize{
    width:100%
}
table {
    // width: 35%;
    border-collapse: collapse;
    border-spacing: 0;
}
.invoice th{
    text-align: center;
    background-color: #ddd;
    padding: 7px;
    border-bottom: 1px solid#CCCCCC;
    border-right: 1px solid#CCCCCC;
    background-color: #ddd;
}
table td{
    padding: 5px;
    border-bottom: 1px solid#CCCCCC;
    /* border-right: 1px solid#CCCCCC; */
}
.invoicedata{
    background-color: #eee;
}
.broucherbtn{
    margin: 0 0 0 361px;
}
.errormsg{
    color:red;
}
/* .broucherbtn{
    margin: 24px 3px 2px -181px;
    
} */
.btn{
    margin: 0 0 17px 0px;
}